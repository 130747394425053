import React from "react"
import * as styles from './HeaderButtonCallout.module.scss'
import OutlineButton from "../../UI/Buttons/OutlineButton/OutlineButton"


const HeaderButtonCallout = ({color, buttonData, children}) => {
    // color: colored or white

    return(
            <article className={` ${styles.callout} ${styles[`${color}`]}`}>
                <h2>
                    {children}
                </h2>
                <OutlineButton type={color} buttonData={buttonData} />
            </article>
    )
}

export default HeaderButtonCallout