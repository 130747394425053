import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import MainTextBlock from './../components/UI/MainTextBlock/MainTextBlock'
import CapabilityGraphic from '../components/UI/Capabilities/CapabilityGraphic/CapabilityGraphic'
import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ServiceIcon from './../images/icons/capabilities/TechServices.inline.svg'
import TechServicesGraphic from './../images/capabilities/Tech-Services.png'
import HeaderButtonCallout from '../components/Callouts/HeaderButtonCallout/HeaderButtonCallout'

const TechnicalServicesPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Technical',
    title2: 'Services',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Providing Full-Spectrum <span>Services</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We offer exceptional technical services in a wide spectrum of
                applications for aviation (fixed wing/rotary wing & unmanned),
                maritime (surface/undersea), and industrial customers. We are
                ISO 9001:2015, AS 9110, and AS 9100D certified and offer quality
                solutions to ensure our customers accomplish their missions.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityGraphic
            src={TechServicesGraphic}
            title="Technical Services Welder at Work"
          />
        </SectionContainer>

        {/* Careers Callout */}
        <SectionContainer
          id="careersCallout"
          color="gray"
          type="fullWidth"
          classes={['inlineChildren']}
        >
          <HeaderButtonCallout
            color="colored"
            buttonData={{
              text: 'View Positions',
              url: '/careers-portal',
              icon: 'uil:briefcase',
              state: { service: `technical` },
            }}
          >
            Explore Careers with{' '}
            <span>
              <em>Technical</em> Services
            </span>
          </HeaderButtonCallout>
        </SectionContainer>

        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Technical Services"
            ServiceIcon={ServiceIcon}
            hexSize="small"
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default TechnicalServicesPage
